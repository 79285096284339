<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Oxidation of Metals </span>
      </div>
      <div ref="ia" style="margin-top: 10px" />
      Oxidation of metals is a chemical reaction in which a metallic element reacts with oxygen to form a metal oxide.<br><br>
      Let's consider a commonly known example of a metal oxidation reaction &mdash; the rusting of iron. The chemical equation describing rusting of iron is shown below:
      $$ 4 Fe + 3 O_2 \rightarrow 2 Fe_2O_3 \text{(rust)} $$
      During this reaction, iron combines with oxygen to form the compound iron oxide (\(Fe_2O_3\)) &mdash; commonly known as rust. This process occurs over time when iron is exposed to water and oxygen, and can lead to the deterioration of the iron structure.<br><br>
      Is this chemical equation balanced or unbalanced? <br><br>
      This chemical equation is balanced because masses of all the chemical elements involved in the reaction (i.e. \(Fe\) and \(O\)) remain constant before and after the reaction.
      <div style="margin-top: 10px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span id="mgk" ref="pg" style="font-family:Oswald"> Learn interactively : Balancing an Oxidation Reaction </span>
      </div>

      <div ref="ib" style="margin-top: 10px" />
      <h5>Session Objectives</h5>
      <div style="margin-top: 10px" />
      In this visually interactive illustration, we learn how to balance a chemical equation describing oxidation of metals in step-by-step fashion. <br><br> Shown in the MagicGraph is a chemical equation that describes the reaction of a metallic element with Oxygen. You will notice that the coefficients of the reactants and products are missing. <br><br> The goal is determine the correct set of coefficients that will balance the chemical equation.
      <div style="margin-top: 10px" />
      <h5>Getting Started</h5>
      <div style="margin-top: 10px" />
      To get started &mdash; <br><br>
      1. Start with entering the coefficients for the reactants and the products in the chemical equation.</br><br>
      2. Once you have provided the coefficients, the MagicGraph automatically calculates the number of moles of each chemical element on the reactant side as well as on the product side. <br><br>
      3. The chemical equation is balanced if the mass of each chemical element before the reaction is same as the mass after the reaction.<br><br>
      4. You can adjust the coefficients to balance the mass of each chemical element before and after the reaction.
    </div>
    <div style="margin-top: 10px" />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox3" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          Navigation Tip: Tap on <i class="shuffler ma-1" /> icon to shuffle. Tap on <i class="eraser ma-1" /> icon to clear your response and start over.
        </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'BalancedEquation',
  components: {
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
    }
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Oxidation Reactions';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Oxidation Reactions',img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Example', img:'/assets/number-2.svg', action: () => this.goto('ib')},
      {title: 'Balancing an Oxidation Reaction', img:'/assets/touch.svg', action: () => this.goto('ic')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceChem', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box3();
  },
  metaInfo() {
  return{ title: 'Oxidation Reactions',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
